<template>
    <div class="card-table">
        <div class="card-table-body ifon-add-title-block mb-4">
            <div class="caz-blocks-sarcho-title">
                <div
                    :class="mode ? 'content__titleday' : 'content__titlenight'"
                    class="content-title d-flex align-center mr-2"
                >
                    <el-badge :value="taskCounts.new ? taskCounts.new : 0" class="item-badge-style">
                        <el-button @click="updateStatus('new')" :class="filterForm.status == 'new' ? 'name-link-task' : ''" size="small"> 
                            {{ $t('message.new_y') }} 
                        </el-button>
                    </el-badge>
                    <el-badge :value="taskCounts.in_process ? taskCounts.in_process : 0" class="item-badge-style">
                        <el-button @click="updateStatus('in_process')" :class="filterForm.status == 'in_process' ? 'name-link-task' : ''" size="small"> 
                            {{ $t('message.in_process') }} 
                        </el-button>
                    </el-badge>
                    <el-badge :value="taskCounts.for_checking ? taskCounts.for_checking : 0" class="item-badge-style">
                        <el-button @click="updateStatus('for_checking')" :class="filterForm.status == 'for_checking' ? 'name-link-task' : ''" size="small"> 
                            {{ $t('message.for_checking') }} 
                        </el-button>
                    </el-badge>
                    <el-badge :value="taskCounts.completed ? taskCounts.completed : 0" class="item-badge-style">
                        <el-button @click="updateStatus('completed')" :class="filterForm.status == 'completed' ? 'name-link-task' : ''" size="small"> 
                            {{ $t('message.completed_y') }}
                        </el-button>
                    </el-badge>
                </div>
            </div>
        </div>
        <!-- end ifon-add-title-block -->

        <div
            class="tabel-collapse radurs-8 p-2 krik-new"
            :class="mode ? 'table__myday' : 'table__mynight'"
            v-loading="loadingData"
        >
            <table
                class="tabel-collapse-style"
                :class="mode ? 'text__day' : 'text__white'"
            >
                <thead>
                    <tr>
                        <th class="tw-5"> {{ $t('message.n') }} </th>
                        <th class="tw-5"> 
                            <i class="el-icon-warning"></i>  
                            <i class="el-icon-warning"></i>  
                            <i class="el-icon-warning"></i>  
                        </th>
                        <th>{{ $t('message.name') }}</th>
                        <th class="tw-5">{{ $t('message.tasks') }}</th>
                        <th class="tw-5">{{ $t('message.deal') }}</th>
                        <th class="tw-5">{{ $t('message.progress') }}</th>
                        <th class="tw-200">{{ $t('message.participants') }}</th>
                        <th class="tw-150">{{ $t('message.labels') }}</th>
                        <th class="tw-150">{{ $t('message.date') }}</th>
                        <th class="tw-265">{{ $t('message.deadline') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="task in list">
                        <td>
                            <b>{{ task.id }}</b>
                        </td>
                        <td>
                            <span class="mm_prior_my_tasks" :class="'prior'+task.priority">{{ task.priority }}</span>
                        </td>
                        

                        <td>
                            <div @click="openTaskUpdate(task)" class="name-link-task">{{ task.name }}</div>
                        </td>

                        <td>
                            <span class="check-task" :class="mode ? 'text__day' : 'text__white'">
                                <i v-if="task.all_mini_tasks &&  task.all_mini_tasks > 0" class="fa-regular fa-square-check mr-1"></i>
                                <span :class="mode ? 'text__day' : 'text__white'">
                                    <span v-if="task.all_mini_tasks &&  task.all_mini_tasks > 0">
                                        {{ task.checked_mini_tasks_count }}/{{ task.all_mini_tasks }}
                                    </span>
                                    <span v-else>
                                        {{ $t('message.no_tasks') }}
                                    </span>
                                </span>
                            </span>
                        </td>
                        <td> {{ task.deal ? task.deal.id : '' }} </td>
                        <td>
                            <el-progress
                                :width="36"
                                :stroke-width="3"
                                type="circle"
                                :percentage="taskPercentage(task)"
                                status="success"
                            ></el-progress>
                        </td>
                        <td>
                            <div class="d-flex f-between mb-3">
                                <div class="users__info h-29">
                                    <div class="user-itme-tasksMy">
                                        <div class="task_user_give_names" v-popover.top="{ name: 'user_'+task.id}">
                                            <img src="/img/flag/employee.svg" alt="" class="task_user_give_img"/>
                                        </div>
                                        
                                        <popover :name="'user_'+task.id" :delay="popoverShowTime" boundary='viewport' :width="250" event="hover" class="p-0 hidden">
                                            <div class="myir">
                                                <div class="top-u-click">
                                                    <img :src="(task.user && task.user.avatar) ? task.user.avatar : '/img/flag/employee.svg'" alt="" class="task_user_give_img" />
                                                    <div class="tt-name">
                                                        <div>{{ task.user ? task.user.name : '' }}</div>
                                                        <span v-if="task.user"><i class="el-icon-warning-outline"></i> ID: {{ task.user.id }} </span>
                                                        <span v-if="task.user && task.user.phone"><i class="el-icon-mobile-phone"></i> Tel: {{ task.user.phone }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </popover>
                                    </div>
                                    <i v-if="task.all_participants && task.all_participants.length > 0" :class="mode ? 'text__day' : 'text__night1' " class="el-icon-arrow-right ml-1 mr-1" ></i>
                                    <div v-for="participant in task.all_participants" class="user-itme-tasksMy">
                                        <div class="task_user_give_names" v-popover.top="{ name: 'participant_'+participant.id}">
                                            <img src="/img/flag/employee.svg" alt="" class="task_user_give_img"/>
                                        </div>
                                        <popover :name="'participant_'+participant.id" :delay="popoverShowTime" boundary='viewport' :width="250" event="hover" class="p-0 hidden">
                                            <div class="myir">
                                                <div class="top-u-click">
                                                    <img :src="(participant && participant.avatar) ? participant.avatar : '/img/flag/employee.svg'" alt="" class="task_user_give_img"/>
                                                    <div class="tt-name">
                                                        <div>{{ participant ? participant.name : '' }}</div>
                                                        <span v-if="participant"><i class="el-icon-warning-outline"></i> ID: {{ participant.id }} </span>
                                                        <span v-if="participant && participant.phone"><i class="el-icon-mobile-phone"></i> Tel: {{ participant.phone }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </popover>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="metka-r p-0">
                                <el-tooltip v-for="label in task.labels" class="item" effect="dark" :content="label.name" placement="top-start">
                                    <span :class="setColor(label.color_id)"></span>
                                </el-tooltip>
                            </div>
                        </td>
                        <td>
                            <div v-if="task.finish_date" class="task-term p-0">
                                <span class="data-deal-task-end ml-0 coloa2">
                                    <i class="el-icon-time"></i>  
                                    {{ task.finish_date }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div v-if="task.begin_date" class="task-term p-0">
                                <span class="data-deal-task-end ml-0" :class="dayDiff(task) >= 0 ? 'coloa2' : 'coloa3'">
                                    <i class="el-icon-time"></i>
                                    {{ task.begin_date }}

                                    <i class="el-icon-arrow-right"></i>
                                    {{ task.end_date }}

                                    <i class="el-icon-arrow-right"></i>
                                    {{ dayDiff(task) }}
                                </span>
                            </div>
                            <div v-else class="task-term p-0">
                                <span class="data-deal-task-end ml-0 color_10">
                                    {{ $t('message.no_deadline') }}
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="my___pagination">
                <crm-pagination
                @c-change="updatePagination"
                :class="mode ? 'pagination__day' : 'pagination__night'"
                :pagination="pagination"
                ></crm-pagination>
                <!-- <Pagination /> -->
            </div>
        </div>
        <div class="app-modal app-modal__full">
            <el-dialog
                class="modal-style-m"
                :visible.sync="taskUpdateDrawer"
                ref="drawerUpdate"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
                :fullscreen="true"
                >
                    <AppModalUpdate
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    />
            </el-dialog>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import AppModalUpdate from "@/views/tasks/components/add-modal-update";

export default {
    name: "reasons",
    mixins: [list],
    components: {AppModalUpdate},

    data() {
        return {
            taskUpdateDrawer: false,
            popoverShowTime: 500,
        };
    },
    created(){
        this.filterForm = JSON.parse(JSON.stringify({...this.filter, status: 'new'}));
        this.updateTaskCounts();
    },

    computed: {
        ...mapGetters({
            colorsInventory: "labels/colorsInventory",
            taskCounts: "tasks/taskCounts",
            list: "tasks/list",
            columns: "tasks/columns",
            pagination: "tasks/pagination",            
            filter: "tasks/filter",
            sort: "tasks/sort",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            updateColorInventory: "labels/colorsInventory",
            updateTaskCounts: "tasks/taskCounts",
            updateList: "tasks/index",
            setPagination: "tasks/setPagination",
            updateSort: "tasks/updateSort",
            updateFilter: "tasks/updateFilter",
            updateColumn: "tasks/updateColumn",
            updatePagination: "tasks/updatePagination",
            refreshData: "tasks/refreshData",
        }),
        openDrawer(board_id) {
            this.board_id = board_id;
            this.appModal = !this.appModal;
        },
        openTaskUpdate(task) {
            this.selectedItem = task;
            this.taskUpdateDrawer = true;
        },
        closeDrawer(drawer) {
            this.appModal = false;
            this.taskUpdateDrawer = false;
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        updateStatus(status){
            this.filterForm.status = status;
        },
        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort,};
            this.updateColorInventory();
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        taskPercentage(task){
            let task_percent = 0;
            if(task && task.all_mini_tasks && task.all_mini_tasks > 0 && task.status != 4){
                task_percent = _.round(parseFloat(task.checked_mini_tasks_count) * 100 / parseFloat(task.all_mini_tasks))
            }else if(task.status == 4){
                task_percent = 100;
            }
            return task_percent;
        },
        setColor(color_id){
            if(color_id){
                let label_color = this.colorsInventory.find(el => el.id == color_id);
                return label_color ? label_color.name : 'color_1';
            }else{
                return 'color_1';
            }
        },
        dayDiff(element) {
            let finish_date = new Date(element.finish_date);
            let current_date = new Date();
            let end_date = new Date(element.end_date);
            if (!isNaN(current_date) && !isNaN(end_date)) {
                if (!isNaN(finish_date) && finish_date.getFullYear() !== 1970) {
                let timeDifference = Math.round(
                    (end_date.getTime() - finish_date.setHours(0, 0, 0)) /
                    (1000 * 3600 * 24)
                );
                return timeDifference;
                } else {
                let timeDifference = Math.round(
                    (end_date.getTime() - current_date.setHours(0, 0, 0)) /
                    (1000 * 3600 * 24)
                );
                return timeDifference;
                }
            }
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('tasks/EMPTY_LIST');
        next()
    },
};
</script>
<style>
    .mm_prior_my_tasks{
        padding: 4px 15px;
        color: #fff;
        border-radius: 3px;
    }
    .krik-new{
        overflow-x: auto;
        width: 99.5%;
    }
    .krik-new .tabel-collapse-style{
        width: 100%;
        max-width: 100%;
        min-width: 1800px;
    }

</style>

